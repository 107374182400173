<template>
  <CChartLine
    :datasets="datasets"
    :options="defaultOptions"
    style="height:300px"
    :labels="labels"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'CChartLineExample',
  components: { CChartLine },
  props: ['yearParams'],
  watch: {
    yearParams: {
      immediate: true,
      handler: function(newVal){
        this.params.year = newVal
        this.getKenaikanPopulasi()
      }
    }
  },  
  data() {
    return {
      // bulan: [
      //   'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
      // ],
      jenisHewan: [],
      dataKenaikan: null,
      datasets: [],
      labels: [],
      datasetKenaikanPopulasi: [],
      params: {
        year: ""
      },
    }
  },
  computed: {

    defaultDatasets () {
      return this.dataset
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
  },
 methods: {
    getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    getFields(input, field) {
      let output = [];
      for (let i = 0; i < input.length ; i++){
        output.push(input[i][field]);
      }
      return output;
    },
    getKenaikanPopulasi() {
      let loading = this.$loading.show();
      this.labels = []
      this.datasets = []
      this.$store
        .dispatch("summary/getKenaikanPopulasi", this.params)
        .then((resp) => {
          // console.log(resp)
          this.$toast.success("berhasil mengambil data");
          let objFix = resp.data
          let bulan = []
          let labelsHewan = Object.keys(objFix['Januari'])
          let total = []
          let dataEntry = []
          let dataset = []

          // Ambil Bulan
          for (const item in objFix) {
            bulan.push(item)
          }

          // ambil total untuk setiap label selama setahun
          labelsHewan.forEach(item => {
            dataset.push({
              label: item,
              data: total
            })
          })

          // masukan nilai ke data
          for (const item in objFix) {
            dataEntry.push(objFix[item])
          }

          let collectData = []

          for (let i = 0; i < labelsHewan.length; i++){
            collectData.push({
              label: labelsHewan[i],
              data: this.getFields(dataEntry, labelsHewan[i]),
              borderColor: this.getRandomColor(),
              fill: false,
              tension: 0.1,
            })
          }

          this.datasets = collectData
          this.labels = bulan

          loading.hide();
        })
        .catch(() => {
          // console.log("ini main dashboard" + e)
          this.$toast.error("gagal mengambil data  \n");
          loading.hide();
        });
    },
  },
  // created() {
  //   this.getKenaikanPopulasi()
  // },
}
</script>
