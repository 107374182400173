<template>
  <CChartDoughnut
    :datasets="defaultDatasets"
    :labels="labels"
    :options="defaultOptions"
    style="height:300px"
  />
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'CChartDoughnutExample',
  components: { CChartDoughnut },
  data() {
    return {
      labels: [],
      total: [],
      colors: [],
      datasetPopulasiHewanTernak: []
    }
  },
  computed: {
    getDatasetPopulasiTernak(){
      let labels = Object.keys(this.datasetPopulasiHewanTernak[0])
      let total = []
      let colors = []
      let dataset = [
        {
          backgroundColor: colors,
          data: total
        }
      ]

      labels.forEach(item => {
        total.push(this.datasetPopulasiHewanTernak[0][item])
        colors.push(this.getRandomColor())
      })

      return dataset
    },
    defaultDatasets () {
      return [
        {
          backgroundColor: this.colors,
          data: this.total
        }
      ]
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    getPopulasiHewanTernak() {
      this.$store
        .dispatch("summary/getPopulasiHewanTernak")
        .then((resp) => {
          // this.$toast.success("berhasil run mengambil data");
          // console.log(resp.data)
          this.labels = Object.keys(resp.data[0])
          this.labels.forEach(item => {
            this.total.push(resp.data[0][item])
            this.colors.push(this.getRandomColor())
          })
        })
        .catch((e) => {
          this.$toast.error("gagal run mengambil data  \n", e);
        });
    },
  },
  mounted() {
    this.getPopulasiHewanTernak()
  },
}
</script>
