<template>
  <CChartBar
    :datasets="datasets"
    :options="computedOptions"
    style="height:300px"
    :labels="labels"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  props: ['kecamatan'],
  watch: {
    kecamatan: {
      immediate: true,
      handler: function(newVal){
        this.params.kecamatan = newVal
        this.getPopulasiKecamatan()
      }
    }
  },
  data() {
    return {
      lp2b: [],
      non_lp2b: [],
      irigasi_macro: [],
      total: [],
      colors: [],
      datasets: [],
      labels: [],
      params: {
        kecamatan: ""
      }
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    getFields(input) {
        let output = [];
        for(const property in input ) {
          output.push(input[property])
        }
        return output;
    },
    getPopulasiKecamatan() {
      this.datasets = []
      this.labels = []
      this.$store
        .dispatch("summary/getPopulasiBerdasarkanKecamatan", this.params)
        .then((resp) => {
          // this.$toast.success("berhasil mengambil data");
          // this.datasetKenaikanPopulasi = resp.data.data
          // console.log(resp.data)
          let dataFix = resp.data
          this.labels = Object.keys(dataFix[0])

          this.datasets.push({
            label: this.params.kecamatan,
            backgroundColor: this.getRandomColor(),
            data: this.getFields(dataFix[0])
          })

        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
        });
    },
  },
  computed: {
    defaultDatasets () {
      return this.irigasi_macro
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  },
  // mounted() {
  //   this.getPopulasiKecamatan()
  // },
}
</script>
