<template>
  <div>
      <div class="d-flex justify-content-start mx-2">

        <div v-for="(item, i) in populasiSummary" :key="i" :style="{backgroundColor : item.color}" class="mx-2 rounded-5 shadow-lg card" style="width: 15rem;">
          <div class="card-body">
            <h6 class="card-title text-white">{{ item.tipe }}</h6>
            <h5 class="card-subtitle mb-2 text-white">{{ item.total }}</h5>
          </div>
        </div>

      </div>

          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Kenaikan Populasi Hewan Tahun {{ params.year }} </b></p>
                  <input style="max-width: 150px" class="form-control form-control-sm mt-2" type="number"
                    v-model="params.year" @change="paramsChange" placeholder="Tahun" min="2000" max="2050">
                  <hr />
                  <div>
                    <CChartLineMainDashboard :yearParams="tahun" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Hewan Populasi Hewan Ternak (%)</b></p>
                  <hr />
                  <CChartDoughnutPopulasiHewanTernak />
                </div>
              </div>
              <div class="col-md-6">
              <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Hewan Kematian Berdasarkan Populasi Hewan (%) </b></p>
                  <hr />
                  <CChartDoughnutKematianTernak/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Pemotongan Hewan Berdasarkan Populasi Hewan (%)</b></p>
                  <hr />
                  <CChartDoughnutPemotonganTernak />
                </div>
              </div>
              <div class="col-md-6">
              <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Persentase Pengeluaran Hewan Berdasarkan Populasi Hewan (%) </b></p>
                  <hr />
                  <CChartDoughnutPengeluaranTernak />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card border-top rounded shadow p-3">
                  <p class="p-0 m-0"><b> Jumlah Populasi Hewan Ternak Kecamatan {{ kecamatanParam }}</b></p>
                  <v-select 
                    @input="paramsKecamatanChange"
                    placeholder="Pilih Kecamatan"
                    class="mt-3" 
                    style="width: 200px"
                    :reduce="kecamatanList => kecamatanList.value" 
                    v-model="params.kecamatanList" 
                    label="key" 
                    :options="kecamatanList"></v-select>
                  <hr />
                  <CChartBarPopulasiKecamatan :kecamatan="kecamatanParam" />
                </div>
              </div>
            </div>
          </div>
  </div>
</template>



<script>
// import axios from "axios";
import CChartDoughnutPopulasiHewanTernak from "../charts/CChartDoughnutPopulasiHewanTernak.vue";
import CChartDoughnutPemotonganTernak from "../charts/CChartDoughnutPemotonganTernak.vue";
import CChartDoughnutKematianTernak from "../charts/CChartDoughnutKematianTernak.vue";
import CChartDoughnutPengeluaranTernak from "../charts/CChartDoughnutPengeluaranTernak.vue";
import CChartBarPopulasiKecamatan from "../charts/CChartBarPopulasiKecamatan.vue";
// import ChartBantuan from "../charts/ChartBantuan.vue";
import CChartLineMainDashboard from "../charts/CChartLineMainDashboard.vue";
// import ChartBantuanPetugas from '../charts/ChartBantuanPetugas.vue'

import kecamatan from "@/utils/internalData";


export default {
  components: {
    CChartDoughnutPopulasiHewanTernak,
    CChartDoughnutPengeluaranTernak,
    CChartDoughnutPemotonganTernak,
    CChartDoughnutKematianTernak,
    CChartBarPopulasiKecamatan,
    CChartLineMainDashboard,
  },
  data() {
    return {
      params: {
        year: new Date().getFullYear()
      },
      tahun: "",
      kecamatanList: kecamatan,
      kecamatanParam: "Sumedang Utara",
      populasiSummary: [],
      colors: ['#0f766e', '#0e7490', '#047857', '#14b8a6', '#3b82f6', '#7c3aed'],
      tipe: ["kelahiran", "kematian", "pemotongan", "pemasukan", "pengeluaran", "populasi"]
    };
  },
  methods: {
    paramsChange(){
      this.tahun = this.params.year
      // console.log(this.tahun)
    },
    paramsKecamatanChange(val){
      this.kecamatanParam = val === null ? this.kecamatanParam : val
    },
    checkTipe(arr, tipe){
      return arr.find(item => item.tipe === tipe.toUpperCase())
    },
    getTipePopulasiSummary(){
      this.$store
      .dispatch("summary/getSummaryTipePopulasi")
      .then((resp) => {
        let dataFix = resp.data
        let dataRaw = []

        dataFix.map((item) => {
          for (const property in item) {
              dataRaw.push({
                tipe: property.toLocaleUpperCase(),
                total: item[property],
                color: this.colors
              })
          }
        })

        for (let i = 0; i < this.tipe.length; i++) {
          this.populasiSummary.push({
            tipe: this.tipe[i].toUpperCase(),
            total: this.checkTipe(dataRaw, this.tipe[i]) !== undefined ? this.checkTipe(dataRaw, this.tipe[i]).total : 0,
            color : this.colors[i]
          })
        }
      })
      .catch((e) => {
        this.$toast.error("gagal mengambil data  \n", e);
      });
    }
  },
  mounted(){
    this.getTipePopulasiSummary()
    this.paramsChange()
  }
};
</script>

<style scoped>
.border-top {
  border-top: 3px solid #1d4374 !important;
}

.scroll {
  overflow-y: auto;
  height: 150px;
}


</style>

<style>
table {
  display: table;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.tab-content {
  margin-top: 20px !important;
}
</style>